<template>
  <div class="table">
    <div class="table__container">
      <h4 class="table__title">{{ title }}</h4>
      <div class="table__right-container">
        <zem-link v-if="role === 'admin' || role === 'front-manager'" @click="archiveCompanies">
          <img :src="require('@/assets/icons/archive.svg')" alt="" />
          <span class="mobile-d-none">Архивировать</span>
        </zem-link>
        <ZemButton :icon="true" @click="showRightSidebar">
          <img :src="require('@/assets/icons/plus.svg')" alt="" />
        </ZemButton>
      </div>
    </div>

    <div class="table__search-block">
      <div class="table__search-block__left">
        <div class="table__search-block__input">
          <ZemInput
            :value="searchQuery"
            class="m-0"
            placeholder="Поиск по ID, организации"
            @input="onSearch($event)"
          ></ZemInput>
          <img :src="require('@/assets/icons/search.svg')" alt="" class="table__search-block__input-icon" />
        </div>
        <p class="table__search-block__search-query">
          Найдено {{ pagination.pagination.total }} из
          {{ pagination.custom.total }}
        </p>
      </div>
      <div class="table__search-block__right">
        <zem-link @click="closeLeftSidebar">
          <img :src="require('@/assets/icons/filter.svg')" alt="" />
          <span>{{ counterFilter }}</span>
          Фильтр
        </zem-link>
        <zem-link v-if="$store.state.companies.selectedItemsForDelete.length > 1" @click="changeRightSidebar">
          <img :src="require('@/assets/icons/filter.svg')" alt="" />
          <span>{{ $store.state.companies.selectedItemsForDelete.length }}</span>
          Выбрано
        </zem-link>
      </div>
    </div>

    <div class="table__table-block">
      <ZemTable
        :columns="columns"
        :pagination="pagination"
        :rows="rows"
        store-name="companies"
        @on-cell-click="onRowClick"
        @update-table="updateData"
      />
    </div>
  </div>
</template>

<script>
import Search from './Search.vue'
import ZemCheckbox from './ui/Checkbox.vue'
import ZemTitle from './ui/Title.vue'
import ZemButton from './ui/ZemButton.vue'
import ZemLink from './ui/ZemLink.vue'
import ZemInput from './ui/ZemInput.vue'
import Search2 from './Search2.vue'
import moment from 'moment'
import ZemTable from '@/components/ui/ZemTable'
import PeopleAndCompaniesService from '@/services/peopleAndCompanies.service'

export default {
  name: 'Table',

  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    counterFilter: {
      type: Number,
      default: 0,
    },
  },

  components: {
    Search,
    ZemCheckbox,
    ZemTitle,
    ZemButton,
    ZemLink,
    Search2,
    ZemInput,
    ZemTable,
  },

  data() {
    return {
      checkboxValue: true,
      searchQuery: '',
      selectedItemsForDelete: [],
      timer: null,
    }
  },

  computed: {
    role() {
      return this.$store.state.user.role
    },
    pagination() {
      if (this.$store.state.companies.companiesPagination !== undefined) {
        return this.$store.state.companies.companiesPagination
      } else {
        return {
          custom: {
            total: 0,
          },
          pagination: {
            current_page: 1,
            total_pages: 1,
            total: 0,
          },
        }
      }
    },
  },

  methods: {
    updateData() {
      PeopleAndCompaniesService.getAllCompanies()
    },
    archiveCompanies() {
      let count = this.$store.state.companies.selectedItemsForDelete.length
      this.$store.state.companies.selectedItemsForDelete.forEach((item, index) => {
        if (index + 1 !== count) {
          PeopleAndCompaniesService.updateCompany(item, {
            is_active: false,
          })
        } else {
          PeopleAndCompaniesService.updateCompany(item, {
            is_active: false,
          }).then(r => {
            PeopleAndCompaniesService.getAllCompanies()
          })
        }
      })
    },
    onRowClick(params) {
      this.$emit('on-selected', params)
    },
    showRightSidebar() {
      this.$emit('show-right-sidebar')
    },
    closeLeftSidebar() {
      this.$store.commit('sidebars/changeLeftSidebar', !this.$store.state.sidebars.leftSidebar)
    },
    changeRightSidebar() {
      this.$store.commit('sidebars/changeRightSidebar', !this.$store.state.sidebars.rightSidebar)
    },
    formatDate(date) {
      return moment.unix(date).format('DD.MM.YYYY')
    },
    onSearch(e) {
      clearTimeout(this.timer)
      this.timer = null
      this.timer = setTimeout(() => {
        this.searchQuery = e
        this.$store.commit('companies/getSearch', e)
        PeopleAndCompaniesService.getAllCompanies()
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  flex-grow: 1;
  height: 100vh;
  background: $color-titan-white;
  box-shadow: 2px 0 8px rgba($color: $color-gray, $alpha: 0.12);
  overflow-x: auto;

  &__right-container {
    display: flex;
    align-items: center;

    .zem-link {
      margin-right: 14px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: $color-mine-shaft;
    margin: 0;
  }

  &__table-block {
    margin-top: 20px;
    //overflow-y: auto;
  }

  &__container {
    padding: 0 20px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__search-block {
    margin: 30px 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;
    }

    &__right {
      position: relative;
      display: flex;

      .zem-link {
        position: relative;

        span {
          position: absolute;
          left: 10px;
          top: -8px;
          font-size: 10px;
        }
      }

      > div:last-child {
        margin-left: 10px;
      }
    }

    &__input {
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      input {
        padding: 5px 24px 5px 8px;
        margin-top: 0;
      }

      &-icon {
        position: absolute;
        right: 9px;
        top: 7px;
        width: 10px;
      }
    }

    &__search-query {
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: $color-gull-gray;
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .table {
    padding-bottom: 20px;
    overflow-x: unset;
    height: unset;

    &__search-block {
      align-items: flex-start;

      &__left {
        flex-direction: column;
        align-items: flex-start;
      }

      &__search-query {
        margin-left: 0;
        margin-top: 5px;
      }
    }
  }
}
</style>
