import { render, staticRenderFns } from "./NewPeopleOrCompany.vue?vue&type=template&id=5f0887e2&scoped=true"
import script from "./NewPeopleOrCompany.vue?vue&type=script&lang=js"
export * from "./NewPeopleOrCompany.vue?vue&type=script&lang=js"
import style0 from "./NewPeopleOrCompany.vue?vue&type=style&index=0&id=5f0887e2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f0887e2",
  null
  
)

export default component.exports